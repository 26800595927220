import { inject, Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { emptyArrayValidator } from '@modules/controls/validators/empty-array.validator';
import { rangeNumberValidator } from '@modules/controls/validators/range-number.validator';
import { disableControlByPermission } from '@permissions/disable-abstract-control';
import { USER } from '@tokens/user.token';
import { LocalizedName, NamedItem } from '@type/named-item.type';
import {
  DEFAULT_STATUS_VALUE,
  ExtendedStatus,
  LIMIT_IN_DAYS,
  ProductStatus,
  StatusForm,
  StatusSaveRequest,
} from '@type/status.type';

@Injectable()
export class StatusFormService {
  private fb = inject(FormBuilder).nonNullable;

  private user = inject(USER);

  private productPrevStatusesCopy: ProductStatus[] = [];

  nextStatusesFormControl = this.fb.control<ProductStatus[]>([]);

  form = this.fb.group({
    name: this.fb.control('', { validators: [Validators.required] }),
    previousStatuses: this.fb.control<ProductStatus[]>([], {
      validators: [Validators.required, emptyArrayValidator],
    }),
    description: this.fb.control(''),
    color: this.fb.control('', { validators: [Validators.required] }),
    icon: this.fb.control('', { validators: [Validators.required] }),
    orders: this.fb.control(true),
    orderFromSupplier: this.fb.control(true),
    shelfSpace: this.fb.control(true),
    lostSales: this.fb.control(true),
    automaticallyChanged: this.fb.control(false),
    automaticallyChangedWarehouse: this.fb.control(false),
    toStatus: this.fb.control<NamedItem>({ id: 0, name: '' }),
    toStatusWarehouse: this.fb.control<NamedItem>({ id: 0, name: '' }),
    notification: this.fb.control(false),
    limitInDays: this.fb.control(false),
    amountOfDays: this.fb.control<number>(LIMIT_IN_DAYS, {
      validators: [rangeNumberValidator([1, 366], { amountOfDays: true }), Validators.required],
    }),
    orderType: this.fb.control<LocalizedName>({ name: '', localized_name: '' }),
  });

  constructor() {
    disableControlByPermission(this.form, !this.user().settingsMatrixFull);
  }

  reset() {
    this.form.controls.previousStatuses.clearValidators();
    this.form.reset(DEFAULT_STATUS_VALUE);
  }

  prepareToFront(status: ExtendedStatus): StatusForm {
    return {
      name: status.name,
      previousStatuses: status.prev_product_statuses.map((s) => ({ ...s, name: s.product_status.name })),
      description: status.description,
      color: status.color,
      icon: status.icon,
      orders: status.orders,
      orderFromSupplier: status.order_from_supplier,
      shelfSpace: status.shelf_space,
      lostSales: status.lost_sales,
      automaticallyChanged: status.automatically_changed,
      automaticallyChangedWarehouse: status.automatically_changed_no_warehouse,
      toStatusWarehouse: status.automatically_changed_no_warehouse ? status.to_status : { id: 0, name: '' },
      toStatus: status.automatically_changed ? status.to_status : { id: 0, name: '' },
      notification: status.notification_when_inventories_empty,
      limitInDays: status.limit_in_days,
      amountOfDays: status.amount_of_days || LIMIT_IN_DAYS,
      orderType: { name: status.order_type_status.localized_name, localized_name: status.order_type_status.name },
    };
  }

  prepareToBackend() {
    return new StatusSaveRequest(this.form.getRawValue(), this.productPrevStatusesCopy);
  }

  setupFormState(status: ExtendedStatus) {
    this.productPrevStatusesCopy = status.prev_product_statuses;

    this.form.patchValue(this.prepareToFront(status));
    this.nextStatusesFormControl.patchValue(
      status.next_product_statuses.map((s) => ({ ...s, name: s.product_status.name })),
    );
    this.setupFormValidators(status);
    this.form.controls.previousStatuses.updateValueAndValidity();
  }

  private setupFormValidators(status: ExtendedStatus) {
    this.nextStatusesFormControl.disable();
    if (status.is_default) this.form.controls.previousStatuses.clearValidators();
    else this.form.controls.previousStatuses.setValidators([Validators.required, emptyArrayValidator]);
  }
}
